import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function LogoutPage() {

  const navigate = useNavigate();

  useEffect(() => {
      localStorage.clear();
      navigate('/login')
      // eslint-disable-next-line
  }, [])

  return (
    <>
    </>
  )
}

export default LogoutPage