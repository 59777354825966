import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import mainContext from '../../contexts/mainContext';
import './Menu.css';
import logo from './Assets/maseeha_logo.png';
import Active_Analytics from './Assets/Active_svg/Analytics.svg';
import Active_Logout from './Assets/Active_svg/Logout.png';
import Active_Management from './Assets/Active_svg/Management.png';
import Inctive_Analytics from './Assets/Inactive_svg/Analytics.svg';
import Inactive_Logout from './Assets/Inactive_svg/Logout.svg';
import Inactive_Management from './Assets/Inactive_svg/Management.svg';
import Auth from '../../Controller/Auth';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Menu = () => {
    
    const context = useContext(mainContext);
    let { profileNavTitle, setLoginUserInfo } = context;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const fetchData = async() => {
            const token = localStorage.getItem('authToken');
            const res = await Auth.getProfile(token);
            if(res.status === 200 || res.status === 201){
                setLoginUserInfo( res.data )
            }else {
                
                localStorage.removeItem('authToken')
                toast.error("Please Login Again",{
                    pauseOnHover: false,
                    theme: "light"
                });

                setTimeout(() => {
                    navigate('/login')
                }, 1000)

            }
        }
        
        setTimeout(() => {
            fetchData();
        }, 1000)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const managementLi = document.querySelector(".management_li");
        if (location.pathname.includes("Management")) {
            managementLi.classList.add("management_li_active");
        }
        else {
            managementLi.classList.remove("management_li_active");
        }

        if (location.pathname.includes("Content")) {
        }
        else {
        }
        // eslint-disable-next-line
    }, [profileNavTitle])
    
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="menu_main_div menu_active">
                <div className="menu_hyratech_logo">
                    <img src={logo} alt="" />
                </div>
                <div className="line"></div>
                <h6>MENU</h6>
                <div className="menu_list">
                    <ul>
                        <li>
                            <Link to="/" className={location.pathname === "/" ? "menu_active_link" : ""}>
                                <span><img src={location.pathname === "/" ? Active_Analytics : Inctive_Analytics} alt="" /></span>
                                Analytics
                            </Link>
                        </li>
                        <li className="li_collapse management_li">
                            <Link to="/Management" className={location.pathname.includes("/Management") ? "menu_active_link" : ""}>
                                <span><img src={location.pathname.includes("/Management") ? Active_Management : Inactive_Management} alt="" /></span>
                                Management
                            </Link>
                            <ul>
                                <li >
                                    <Link to="/Management/Organisations" className={location.pathname.includes("/Management/Organisations") ? "menu_active_link" : ""}>Organisations</Link>
                                </li>
                                <li >
                                    <Link to="/Management/User" className={location.pathname.includes("/Management/User") ? "menu_active_link" : ""}>User</Link>
                                </li>
                                <li >
                                    <Link to="/Management/website-user" className={location.pathname.includes("/Management/website-user") ? "menu_active_link" : ""}>Website-User</Link>
                                </li>
                                
                                
                                
                                
                            </ul>
                        </li>
                        
                        <li >
                            <Link to="/logout">
                                <span><img src={location.pathname === "/login" ? Active_Logout : Inactive_Logout} alt="" /></span>
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="menu_footer">
                    <span>App Version 1.0.0</span>
                    <span>&copy; 2021 All rights reserved</span>
                </div>
            </div>
        </>
    )
}

export default Menu
