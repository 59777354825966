import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const AddEmergencyNumber = () => {

    const context = useContext(mainContext);
    const {
        setCanvasTitle,
        setCanvasSubTitle,
    } = context;




    useEffect(() => {
        setCanvasTitle("Add number");
        setCanvasSubTitle("Detail");
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="knowledge_base_view_main_div">
                <div>
                    <div className="input_box">
                        <div className="number">Emergency number</div>
                        <input type="number" placeholder='XX XXXX' id='number' />
                    </div>

                    <div className="input_box">
                        <label htmlFor="desc">Emergency decription</label>
                        <textarea id='desc' placeholder='Enter description here' style={{ height: "10vh" }} />
                    </div>

                </div>


                <div className="btns">
                    <button className='add_btn'>Add Number</button>
                </div>
            </div>
        </>
    )
}

export default AddEmergencyNumber
