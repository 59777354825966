import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext'
import './Add.css'
const AddKnowledgeBase = () => {
    const context = useContext(mainContext);
    const {
        setCanvasTitle,
        setCanvasSubTitle
    } = context;
    useEffect(() => {
        setCanvasTitle("Add knowledgebase");
        setCanvasSubTitle("Detail");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="add_knowledgebase_main_div">

                <div>
                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Level</div>
                        <div className="check_box">
                            <div>
                                <input type="checkbox" name="" id="gold_input" />
                                <label htmlFor="gold_input">Gold</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="platinum_input" />
                                <label htmlFor="platinum_input">Platinum</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="blue_input" />
                                <label htmlFor="blue_input">Blue</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="all_input" />
                                <label htmlFor="all_input">All</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <label htmlFor="knowledge_body">Title</label>
                        <textarea id="knowledge_body" placeholder='enter here'></textarea>
                    </div>

                    <div className="input_box">
                        <label htmlFor="knowledge_content">Content</label>
                        <textarea id="knowledge_content" placeholder='enter here'></textarea>
                    </div>

                </div>


                <div className='btns'>
                    <button className='add_btn'>Add knowledgebase</button>
                </div>
            </div>
        </>
    )
}

export default AddKnowledgeBase
