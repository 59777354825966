import React, { useContext } from 'react'
import mainContext from '../../contexts/mainContext';
import "./SearchHeader.css";
const SearchHeader = (props) => {
    const context = useContext(mainContext);
    const { setinCanvas } = context;
    const { 
        title, 
        titleSupport, 
        addMoreSection, 
        addSubTitle, 
        subTitle, 
        records, 
        filterOf, 
        searchPlaceHolder, 
        addSearchSection 
    } = props;
    
    const handleSearch = () => {
        // call the function
    }

    const handleAdd = () => {
        setinCanvas(`Add new ${subTitle}`)
    }
    const handleFilter = () => {
        setinCanvas(filterOf);
    }
    return (
        <>
            <div className="search_header" style={{ marginBottom: "1%" }}>
                <div className="search_header_left_section">
                    <span> {addSubTitle && <div>{subTitle}</div>} {records ? `${records} records` : ""}</span>
                    {
                        addMoreSection &&
                        <button className="add_more_btn" onClick={handleAdd}>Add more {subTitle}</button>
                    }
                    {
                        title ?
                            <div className="title">
                                {title} <small>{titleSupport}</small>
                            </div> : ""
                    }
                </div>
                <div className="search_header_right_section">
                    {
                        addSearchSection &&
                        <div className="search_div">  
                            <input type="text" placeholder={searchPlaceHolder} id='searchInput' />
                            <button className="search_btn" onClick={handleSearch}>Search</button>
                        </div>
                    }
                    <button className="filter_btn" onClick={handleFilter}>Filter</button>
                </div>
            </div>
        </>
    )
}

export default SearchHeader
