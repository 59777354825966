import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const OrganisationProfile = () => {
    
    const context = useContext(mainContext);

    const {
        setinCanvas,
        setCanvasTitle,
        setCanvasSubTitle
    } = context;


    const handleEdit = () => {
        setinCanvas("Edit organisation profile")
    }

    useEffect(() => {
        setCanvasTitle("organisation Name");
        setCanvasSubTitle("View or edit profile");
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <div className="profile_main_div ">
                <div className='upper_section'>
                    <div className='input_box' style={{ padding: "0" }}>
                        <div>Profile image</div>
                        <div className='img_div'><img src="https://i.picsum.photos/id/915/200/300.jpg?hmac=dzVwmjYlIh3MdKz2l08oFpp1y3XxMyu_1vjGp5Dycd0" alt="" /></div>
                    </div>

                    <div className='input_box'>
                        <div>Full name</div>
                        <div style={{ fontWeight: "bold" }}>Birla</div>
                    </div>

                    <div className='input_box'>
                        <div>Phone number</div>
                        <div style={{ fontWeight: "bold" }}>0000000000</div>
                    </div>

                    <div className='input_box'>
                        <div>Email address</div>
                        <div style={{ fontWeight: "bold" }}>birla@dummy</div>
                    </div>

                    <div className='input_box'>
                        <div>Organisation documents</div>
                        <div className="red" style={{ fontWeight: "bold" }}>Unverified</div>
                    </div>

                    <div className='input_box'>
                        <div>Organisation ID</div>
                        <div style={{ fontWeight: "bold" }}>123</div>
                    </div>

                    <div className='input_box'>
                        <div>Contact started on</div>
                        <div style={{ fontWeight: "bold" }}>2022-08-26</div>
                    </div>

                    <div className='input_box'>
                        <div>Contact expired on</div>
                        <div style={{ fontWeight: "bold" }}>2022-08-26</div>
                    </div>
                </div>
                <div className='lower_section' style={{ display: "flex", columnGap: "5%" }}>
                    <button onClick={handleEdit}>Edit organisation</button>
                </div>
            </div>
        </>
    )
}

export default OrganisationProfile
