import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import mainContext from '../../contexts/mainContext';
import './ManagementPage.css';
import ManagementPageBody from './ManagementPageBody/ManagementPageBody.jsx';
const ManagementPage = () => {
    const location = useLocation();
    const context = useContext(mainContext);
    let { setProfileNavTitle } = context;
    useEffect(() => {
        setProfileNavTitle("Management");
    }, [setProfileNavTitle])
    return (
        <>
            <div className="management_main_div">
                {
                    location.pathname === "/Management" &&
                    <ManagementPageBody />
                }
            </div>
        </>
    )
}

export default ManagementPage
