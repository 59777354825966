import React, { useContext, useEffect } from 'react'
import mainContext from '../../contexts/mainContext';
import './Pagination.css'
const Pagination = (props) => {
    const url = new URLSearchParams(window.location.search);
    const context = useContext(mainContext);
    const { activePagination, setActivePagination, } = context;
    const { totalPage, callFunction } = props;
    const arr = [];
    for (let index = 1; index <= totalPage; index++) {
        arr.push(index);
    }
    const handleClick = (e, page) => {
        console.log({ pageis: page })
        document.getElementById(`pagination_li_${activePagination}`).classList.remove("pagination_active");
        setActivePagination(page);
        callFunction(page);
        e.target.classList.add("pagination_active");
    }
    const handlePrevious = () => {
        document.getElementById(`pagination_li_${(parseInt(activePagination) - 1)}`).click();
    }
    const handleNext = () => {
        document.getElementById(`pagination_li_${(parseInt(activePagination) + 1)}`).click();
    }
    useEffect(() => {
        if (url.get("page")) {
            setActivePagination(url.get("page"));
            document.getElementById(`pagination_li_1`).classList.remove("pagination_active");
            document.getElementById(`pagination_li_${url.get("page")}`).classList.add("pagination_active");
        } else {
            setActivePagination(1);
            document.querySelector(".pagination_li").classList.add("pagination_active");
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="pagination">
                <ul id='pagination_ul'>
                    <button onClick={handlePrevious} disabled={parseInt(activePagination) === 1 ? true : false}><i className="bi bi-chevron-left"></i></button>
                    {
                        arr.map((page) => {
                            return (<li key={page} onClick={(e) => { handleClick(e, page) }} className='pagination_li' id={`pagination_li_${page}`}>{page}</li>)
                        })
                    }
                    <button onClick={handleNext} disabled={parseInt(activePagination) >= totalPage ? true : false}><i className="bi bi-chevron-right"></i></button>
                </ul>
            </div>
        </>
    )
}

export default Pagination
