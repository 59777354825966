import axios from "axios";
const authToken = localStorage.getItem("authToken");
// const authToken = process.env.REACT_APP_AUTHTOKEN;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Suffix = process.env.REACT_APP_SUFFIX;

class Auth{

    async getProfile(key){
        
        try {
            
            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/admin/profile`,
                headers: {
                    'Authorization': `Bearer ${key}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;
            
        } catch (error) {
            return error.response
        }
    }

    async updateProfile(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/admin/profile`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;

        }catch(err){
            return err.response
        }
    }

    async login(data){

        try {

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/login`,
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;
            
        } catch (error) {
            return error.response
        }
    }

}

const auth = new Auth();

export default auth;