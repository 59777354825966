import React, { useContext, useEffect, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import UploadPhoto from '../../../Controller/UploadPhoto';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const EditUser = () => {
    
    const url = new URLSearchParams(window.location.search);
    const context = useContext(mainContext);
    const { 
        setCanvasTitle,
        setCanvasSubTitle,
        allFunctions,
        userDetail
    } = context;

    const { getUserList, handleCanvasClose } = allFunctions;
    const [ flag, setFlag ] = useState(false);
    
    const [ organisationState, setOrganisationState ] = useState(userDetail);
    const { name, email, mobileno, countrycode, profileimage, password } = organisationState;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrganisationState((intialState) => { return { ...intialState, [name]: value } })
    }

    const handleProceed = async () => {

        if(flag) return;
        setFlag(true);

        console.log({ organisationState })

        const res = await Organisation.updateUser(organisationState);
        console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("User Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getUserList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        setFlag(false);

    }
    
    const handleImg = (id) => {
        console.log('handle image')
        document.getElementById(id).click();
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Admin");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        console.log({ image })
        const { path } = image;
        setOrganisationState((initialState) => { return { ...initialState, [name]: path } })

        // setImage(e.target.files[0]);
    }
    
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">name</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name' name='name' value={name} onChange={handleChange} />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <div className='select_input'>
                            <input type="email" placeholder='Enter Mail' id='select_input' name='email' value={email} onChange={handleChange} />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Password (optional)</label>
                        <div className='select_input'>
                            <input type="email" placeholder='Enter Password' id='select_input' name='password' value={password} onChange={handleChange} />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select name='countrycode' value={countrycode} onChange={handleChange}>
                                <option value=""></option>
                                <option value="91">+91</option>
                                <option value="201">+201</option>
                                <option value="111">+111</option>
                            </select>
                            <input type="number" placeholder='Enter Mobile number' id='mobile' name='mobileno' value={mobileno} onChange={handleChange}  />
                        </div>
                    </div>


                    <div className='input_box upload_photo'>
                        <label htmlFor="">Logo</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="profileimage" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                profileimage !== ""
                                ? <img src={`${SERVER_URL}/${profileimage}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload profileimage</button>
                        </div>
                    </div>

                    {/* <div className='input_box upload_photo'>
                        <label htmlFor="">Banner</label>
                        <input type="file" style={{ display: "none" }} id="img_input_2" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="banner" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                banner !== ""
                                ? <img src={`${SERVER_URL}/${banner}`} style={{ width: "90px" }} alt="" />
                                :  ""
                            }
                            <button onClick={() => handleImg('img_input_2')}>Upload Banner</button>
                        </div>
                    </div> */}

                </div>
                <div className='lower_section'>
                    <button onClick={handleProceed}>Update User</button>
                </div>
            </div>
        </>
    )
}

export default EditUser
