import React, { useContext, useEffect, useState } from 'react';
// import { DisplayLineChart } from './LineChart.jsx'
import "./AnalyticsPage.css";
import AnalyticsPageCard from './AnalyticsPageCard';
import mainContext from '../../contexts/mainContext.jsx';
import Organisation from '../../Controller/Organisation';

const AnalyticsPage = () => {
    
    const context = useContext(mainContext);
    let { setProfileNavTitle, param } = context;
    const [ totalOrganisation, setTotalOrganisation ] = useState(0);
    const [ totalUser, setTotalUser ] = useState(0);

    const getOrganisationData = async() => {
        const res = await Organisation.getOrganisationList(param);
        if(res.status === 200 || res.status === 201){
            setTotalOrganisation(res.data.count)
        }
    }

    const getUserData = async() => {
        const res = await Organisation.getUserList(param);
        if(res.status === 200 || res.status === 201){
            setTotalUser(res.data.count)
        }
    }

    useEffect(() => {
        getOrganisationData()
        getUserData()
        setProfileNavTitle("Analytics");
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <div className="component_body">
                <div className="analytics_first_section">
                    <AnalyticsPageCard status={false} title="Total Organisation" num={totalOrganisation} per="-10%" desc="" icon={true} />
                    <AnalyticsPageCard status={false} title="Total User" num={totalUser} per="-10%" desc="" icon={true} />
                </div>
                <div className="analytics_second_section">
                    <div className="analytics_line_chart">
                        {/* <DisplayLineChart /> */}
                    </div>
                    <div className="analytics_bar_chart">

                    </div>
                </div>
                <div className="analytics_third_section">
                </div>
            </div>
        </>
    )
}

export default AnalyticsPage
