import React, { useContext, useEffect, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import UploadPhoto from '../../../Controller/UploadPhoto';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const EditOrganisationBankDetail = () => {
    
    const url = new URLSearchParams(window.location.search);
    const context = useContext(mainContext);
    const { 
        setCanvasTitle,
        allFunctions,
        organisationDetail,  
        setCanvasSubTitle,
    } = context;

    console.log({ organisationDetail })

    const { handleCanvasClose, getOrganisationList  } = allFunctions

    const initialState = { accountholdername: '', bankname: '', ifsccode: '', accountnumber: '', swiftcode: '', cancelcheque: '', country: '' }

    const [ organisationState, setOrganisationState ] = useState(organisationDetail.bankdetail ? organisationDetail.bankdetail : initialState );
    const { accountholdername, bankname, ifsccode, accountnumber, swiftcode, cancelcheque, country } = organisationState;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrganisationState((intialState) => { return { ...intialState, [name]: value } })
    }

    const handleProceed = async () => {

        const data = { ...organisationDetail, bankdetail: organisationState }

        const res = await Organisation.updateOrganisation(data);
        console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Organisation Is Updated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getOrganisationList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                return toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                return toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

        // setinCanvas("Add organisation documents");
    }
    
    const handleImg = (id) => {
        console.log('handle image')
        document.getElementById(id).click();
    }
    
    useEffect(() => {
        setCanvasTitle("Edit");
        setCanvasSubTitle("Organisation Bank");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        console.log({ image })
        const { path } = image;
        setOrganisationState((initialState) => { return { ...initialState, [name]: path } })

        // setImage(e.target.files[0]);
    }
    
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Account Holder Name</label>
                        <input type="text" placeholder='Enter Name' id='compnay_name' 
                            name='accountholdername' value={accountholdername} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Bank Name</label>
                        <input type="text" placeholder='Enter Bank Name' id='compnay_name' 
                            name='bankname' value={bankname} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Ifsc Code</label>
                        <input type="text" placeholder='Enter IFSC Code' id='compnay_name' 
                            name='ifsccode' value={ifsccode} onChange={handleChange} 
                        />
                    </div>

                    
                    <div className='input_box'>
                        <label htmlFor="">Account Number</label>
                        <input type="number" placeholder='Enter Account Number' id='compnay_name' 
                            name='accountnumber' value={accountnumber} onChange={handleChange} 
                        />
                    </div>
                    
                    <div className='input_box'>
                        <label htmlFor="">Swift Code</label>
                        <input type="text" placeholder='Enter Swift Code' id='compnay_name' 
                            name='swiftcode' value={swiftcode} onChange={handleChange} 
                        />
                    </div>
                    
                    <div className='input_box'>
                        <label htmlFor="">Country</label>
                        <input type="text" placeholder='Enter Country' id='compnay_name' 
                            name='country' value={country} onChange={handleChange} 
                        />
                    </div>

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Cancel Cheque</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="cancelcheque" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                cancelcheque !== ""
                                ? <img src={`${SERVER_URL}/${cancelcheque}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload image</button>
                        </div>
                    </div>

                </div>
                <div className='lower_section'>
                    <button onClick={handleProceed} >Update Organisation</button>
                </div>
            </div>
        </>
    )
}

export default EditOrganisationBankDetail
