import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext'

const EditCompanyBankDetails = () => {
    const context = useContext(mainContext);
    const { setCanvasTitle, setCanvasSubTitle } = context;
    useEffect(() => {
        setCanvasSubTitle("Update information");
        setCanvasTitle("Edit bank details");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="edit_driver_bank_details_main_div">
                <div>
                    <div className='input_box'>
                        <label htmlFor="">Bank name</label>
                        <input type="text" placeholder='Bank Name' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Account number</label>
                        <input type="text" placeholder='Account Number' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Account name</label>
                        <input type="text" placeholder='Account Name' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">BIC code</label>
                        <input type="text" placeholder='BIC code' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Bank address</label>
                        <textarea placeholder='Bank Address'></textarea>
                    </div>
                </div>
                <div className="btns">
                    <button className='add_btn'>Update bank details</button>
                </div>
            </div>
        </>
    )
}

export default EditCompanyBankDetails
