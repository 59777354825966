import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext'

const EditPromo = () => {
    const context = useContext(mainContext);
    const { setCanvasTitle } = context;
    useEffect(() => {
        setCanvasTitle("Edit promo");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="edit_promo_main_div">
                <div>
                    <div className="input_box">
                        <label htmlFor="code_input">Code</label>
                        <input type="text" id='code_input' placeholder='MaseehaSUPER' />
                    </div>

                    <div className="input_box">
                        <label htmlFor="type_input">Type</label>
                        <input type="text" id='type_input' placeholder='30' />
                    </div>

                    <div className="input_box">
                        <label htmlFor="from_input">Starting on</label>
                        <input type="text" id='from_input' placeholder='20/11/2021' />
                    </div>

                    <div className="input_box">
                        <label htmlFor="to_input">Expiring on</label>
                        <input type="text" id='to_input' placeholder='20/12/2021' />
                    </div>

                    <br />

                    <div className="input_box">
                        <div className="title">Promo applicable for (profile)</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="personal_check" />
                                <label htmlFor="personal_check">Personal</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="business_check" />
                                <label htmlFor="business_check">Business</label>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="btns">
                    <button className="add_btn">Update promocode</button>
                </div>
            </div>
        </>
    )
}

export default EditPromo
