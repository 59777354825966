import React from 'react'
import { useLocation } from 'react-router';
import './HomePage.css';
import Analytics from '../Analytics/AnalyticsPage.jsx';
import Menu from '../Menu/Menu.jsx';
import AdminNotifications from '../Notification/AdminNotifications.jsx'
import ProfileNav from '../ProfileNav/ProfileNav.jsx';
import ManagementPage from '../Management/ManagementPage.jsx';
import OrganisationPage from '../Management/Organisations/OrganisationPage.jsx';
import OffCanvas from '../OffCanvas/OffCanvas.jsx';
// import OrganisationDetailViewPage from '../Management/Organisations/DetailView/OrganisationDetailViewPage';
// import OrganisationExpendituresPage from '../Management/Organisations/DetailView/OrganisationExpendituresPage';
// import OrganisationEmployeeProfile from '../Management/Organisations/DetailView/OrganisationEmployeeProfile';
import UserPage from '../Management/User/UserPage';
import WebsiteUserPage from '../Management/WebSiteUser/WebsiteUserPage';

const HomePage = () => {
    const location = useLocation();
    return (
        <>
            <div className="admin_main_div">
                <Menu />
                <OffCanvas />
                <div className="detail_section">
                    <ProfileNav />
                    <span id="detail_section_before"></span>
                    {
                        location.pathname === "/" &&
                        <Analytics />
                    }
                    {
                        location.pathname === "/Admin-Notifications" &&
                        <AdminNotifications />
                    }
                    {
                        location.pathname === "/Management" &&
                        <ManagementPage />
                    }
                    {
                        location.pathname === "/Management/Organisations" &&
                        <OrganisationPage />
                    }

                    {
                        location.pathname === "/Management/User" &&
                        <UserPage />
                    }

                    {
                        location.pathname === "/Management/website-user" &&
                        <WebsiteUserPage />
                    }

                </div>
            </div>
        </>
    )
}

export default HomePage
