import React, { useContext, useEffect, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
import UploadPhoto from '../../../Controller/UploadPhoto';
import { ToastContainer, toast } from 'react-toastify';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AddOrganisation = () => {
    
    const context = useContext(mainContext);
    const { 
        setCanvasTitle,
        setOrganisationDetail,  
        setCanvasSubTitle,
        setinCanvas
    } = context;
    
    const intialState = { 
        name: '', 
        email: '', 
        countrycode: '', mobileno: '', banner: '', 
        logo: '', map_url: '', country: '',
        currency: '', heading: '', title: ''
    };
    const [ organisationState, setOrganisationState ] = useState(intialState);
    const { name, email, mobileno, banner, logo, map_url, country, currency, heading, title } = organisationState;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrganisationState((intialState) => { return { ...intialState, [name]: value } })
    }

    const handleProceed = async () => {

        console.log({ organisationState })
        setOrganisationDetail(() => organisationState);
        setinCanvas('Add Organisation Bank')
        return;

        // const res = await Organisation.createOrganisation(organisationState);
        // console.log({ res })
        // if(res && (res.status === 200 || res.status === 201)){
        //     toast.success("Organisation Is Created Successfully",{
        //         pauseOnHover: false,
        //         theme: "light"
        //     });

        //     setTimeout(async() => {
        //         await getOrganisationList(url.get('page'))
        //         await handleCanvasClose();
        //     }, 2000)
        // }
        // else{
        //     if(typeof(res.data.message) == 'object' )
        //         return toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
        //             pauseOnHover: false,
        //             theme: "light"
        //         });
        //     else{
        //         return toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
        //             pauseOnHover: false,
        //             theme: "light"
        //         });
        //     }

        // }

        // setinCanvas("Add organisation documents");
    }
    
    const handleImg = (id) => {
        console.log('handle image')
        document.getElementById(id).click();
    }
    
    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("Organisation");
        // eslint-disable-next-line
    }, [])

    const changeAvatar = async(e) => {

        const { name } = e.target;

        const err = await UploadPhoto.checkImage(e.target.files[0]);
        if(err) {
            return toast.warning(err,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
        console.log({ image })
        const { path } = image;
        setOrganisationState((initialState) => { return { ...initialState, [name]: path } })

        // setImage(e.target.files[0]);
    }
    
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Organisation name</label>
                        <input type="text" placeholder='Enter Organisation Name' id='compnay_name' name='name' value={name} onChange={handleChange} />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <div className='select_input'>
                            <input type="email" placeholder='Enter Mail' id='select_input' name='email' value={email} onChange={handleChange} />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Heading</label>
                        <input type="text" placeholder='Enter Organisation Heading' id='compnay_name' name='heading' value={heading} onChange={handleChange} />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Title</label>
                        <input type="text" placeholder='Enter Organisation Title' id='compnay_name' name='title' value={title} onChange={handleChange} />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select name='countrycode' onChange={handleChange}>
                                <option value=""></option>
                                <option value="91">+91</option>
                                <option value="201">+201</option>
                                <option value="111">+111</option>
                            </select>
                            <input type="number" placeholder='Enter Mobile number' id='mobile' name='mobileno' value={mobileno} onChange={handleChange}  />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Map Url</label>
                        <input type="text" placeholder='enter map location' id='full_name' name='map_url' value={map_url} onChange={handleChange} />
                    </div>
                    
                    <div className='input_box'>
                        <label htmlFor="">Country</label>
                        <input type="text" placeholder='enter Country' id='full_name' name='country' value={country} onChange={handleChange} />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Currency</label>
                        <input type="text" placeholder='enter Currency' id='full_name' name='currency' value={currency} onChange={handleChange} />
                    </div>


                    <div className='input_box upload_photo'>
                        <label htmlFor="">Logo</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="logo" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                logo !== ""
                                ? <img src={`${SERVER_URL}/${logo}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Upload logo</button>
                        </div>
                    </div>

                    <div className='input_box upload_photo'>
                        <label htmlFor="">Banner</label>
                        <input type="file" style={{ display: "none" }} id="img_input_2" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="banner" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                banner !== ""
                                ? <img src={`${SERVER_URL}/${banner}`} style={{ width: "90px" }} alt="" />
                                :  ""
                            }
                            <button onClick={() => handleImg('img_input_2')}>Upload Banner</button>
                        </div>
                    </div>

                </div>
                <div className='lower_section'>
                    <button onClick={handleProceed}>Create Organisation</button>
                </div>
            </div>
        </>
    )
}

export default AddOrganisation
