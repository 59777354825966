import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext'
import "./view.css"
const KnowledgeBase = () => {
    const context = useContext(mainContext);
    const { setinCanvas, setCanvasTitle, setCanvasSubTitle } = context;
    const handleEdit = () => {
        setinCanvas("Edit knowledgebase")
    }
    useEffect(() => {
        setCanvasTitle("Knowledgebase");
        setCanvasSubTitle("Detail");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="knowledge_base_view_main_div">
                <div>

                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Level</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="gold_input" />
                                <label htmlFor="gold_input">Gold</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="platinum_input" />
                                <label htmlFor="platinum_input">Platinum</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="blue_input" />
                                <label htmlFor="blue_input">Blue</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="all_input" />
                                <label htmlFor="all_input">All</label>
                            </div>
                        </div>
                    </div>

                    <p className="knowledge_base_title">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero animi
                        aperiam tempora molestias  maiores!
                    </p>

                    <p className="knowledge_base_content">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero animi
                        aperiam tempora molestias  maiores

                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas
                        voluptatum repudiandae aliquam labore blanditiis aliquid magni et dolores reprehenderit eligendi.
                        Perferendis exercitationem aspernatur odit repellat vero id voluptatum labore nostrum.
                    </p>

                </div>


                <div className="btns">
                    <button className='edit_btn' onClick={handleEdit}>Edit knowledgebase</button>
                    <button className='delete_btn'>Delete knowledgebase</button>
                </div>
            </div>
        </>
    )
}

export default KnowledgeBase
