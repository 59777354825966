import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import mainContext from '../../contexts/mainContext';
import Notification_logo from './Assets/Notification.png'
import './ProfileNav.css';
const ProfileNav = (props) => {
    const context = useContext(mainContext);
    const { profileNavTitle, setinCanvas } = context;
    const handleAdminProfile = () => {
        setinCanvas("Admin Profile");
    }
    return (
        <>
            <nav className="profile_nav">
                <strong>{profileNavTitle}</strong>
                <div className="admin_profile">
                    <div className="admin_name">
                        Maseeha
                        <span>Maseeha Admin</span>
                    </div>
                    <div className="admin_profile_pic" onClick={handleAdminProfile}>
                        <img src="https://picsum.photos/200/300" alt="" />
                    </div>
                    <div className="notification_icon">
                        <Link to="/Admin-Notifications">
                            <img src={Notification_logo} alt="" />
                        </Link>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default ProfileNav
