import React, { useState } from 'react'
import mainContext from './mainContext'

const MainState = (props) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const nth = function (d) {
        if (d > 3 && d < 21) return "th";
        switch (d % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };
    const [allFunctions, setAllFunctions] = useState({})
    const [totalFetchDataCount, setTotalFetchDataCount] = useState(12);
    let param = {
        limit: 10,
        page: "1",
        filterDate: {
            from: "",
            to: ""
        },
        status: "",
        searchtext: "",
        companyId: "",
        vendorId: "",
        category: ""
    }
    const [activePagination, setActivePagination] = useState(1);
    const [profileNavTitle, setProfileNavTitle] = useState("");
    const [inCanvas, setinCanvas] = useState("");
    const [canvasTitle, setCanvasTitle] = useState("");
    const [canvasSubTitle, setCanvasSubTitle] = useState("");

    const [organisationDetail, setOrganisationDetail] = useState({});
    const [userDetail, setUserDetail] = useState({});
    const [loginUserInfo, setLoginUserInfo] = useState({});

    return (
        <mainContext.Provider value={{
            param,
            nth,
            months,
            totalFetchDataCount,
            setTotalFetchDataCount,

            allFunctions,
            setAllFunctions,

            profileNavTitle,
            setProfileNavTitle,

            inCanvas,
            setinCanvas,
            canvasTitle,
            setCanvasTitle,
            canvasSubTitle,
            setCanvasSubTitle,

            activePagination,
            setActivePagination,

            organisationDetail,
            setOrganisationDetail,

            loginUserInfo,
            setLoginUserInfo,

            userDetail,
            setUserDetail

        }} >
            {props.children}
        </mainContext.Provider>
    )
}

export default MainState
