import React from 'react'
import "./Notifications.css"
const Notifications = () => {
    return (
        <>
            <div className="notification_main_div">
                <div className="upper_section">
                    <button className="unread_btn">Unread</button>
                </div>
                <div className="middle_section">
                    <p style={{fontWeight:"bold"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio vero quia sed ab, enim quidem veniam quae sequi dolor placeat.</p>
                    <br />
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing eli
                        t. Illo error eligendi debitis unde voluptatum ipsam 
                        omnis molestias culpa a nostrum. Iure, doloremque! Iste, 
                        esse. Adipisci itaque tempore quibusdam eaque repudiandae asp
                        eriores debitis est? Hic recusandae cumque corporis ipsum, har
                        um quas deserunt officiis et! Nesciunt earum tempore fuga impedi
                        t porro. At.</p>
                </div>
                <div className="lower_section">
                    <button>Mark as read</button>
                </div>
            </div>
        </>
    )
}

export default Notifications
