import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext'

const EditCompany = () => {
    
    const context = useContext(mainContext);
    const { setCanvasTitle, setCanvasSubTitle } = context;

    const handleImg = () => {
        document.getElementById("img_input").click()
    }
    
    useEffect(() => {
        setCanvasTitle("Edit company");
        setCanvasSubTitle("update basic information");
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="edit_profile_main_div">
                <div className='upper_section'>

                    <div className='input_box'>
                        <label htmlFor="">Company name</label>
                        <input type="text" placeholder='enter name here' id='companyName' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">City</label>
                        <div className='select_input'>
                            <input type="text" placeholder='Select city' id='city' />
                            <select>
                                <option ></option>
                                <option value="Delhi">Delhi</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Pune">Pune</option>
                            </select>
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select id='selected_mobile_code'>
                                <option value="+971">+971</option>
                                <option value="+91">+91</option>
                                <option value="+201">+201</option>
                                <option value="+111">+111</option>
                            </select>
                            <input type="number" placeholder='XX XXXX XXXX' id='mobile' />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <input type="text" placeholder='enter email here' id='email' />
                    </div>

                    <div className='input_box img_div'>
                        <label htmlFor="">Profile image</label>
                        <input type="file" id='img_input' style={{ display: "none" }} />
                        <div>
                            <button onClick={handleImg}>Replace image</button>
                        </div>
                    </div>
                </div>
                <div className='lower_section'>
                    <button>Update company profile</button>
                </div>
            </div>
        </>
    )
}

export default EditCompany
