import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const FAQRider = () => {
    const context = useContext(mainContext);
    const { setinCanvas, setCanvasTitle, setCanvasSubTitle, currentRiderFAQ } = context;

    const handleEdit = () => {
        setinCanvas("Edit faq-rider")
    }

    useEffect(() => {
        setCanvasTitle("FAQ");
        setCanvasSubTitle("Rider FAQ details");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            {
                currentRiderFAQ &&
                <div className="notification_main_div">
                    <div className="middle_section">
                        <p style={{ fontWeight: "bold" }}>Question</p>
                        <br />
                        <p>Answer</p>
                    </div>
                    <div className="lower_section" style={{ display: "flex", columnGap: "5%" }}>
                        <button onClick={handleEdit}>Edit FAQ</button>
                        <button className='delete_btn'>Delete FAQ</button>
                    </div>
                </div>
            }
        </>
    )
}

export default FAQRider
