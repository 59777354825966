import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';


const AddFAQRider = () => {
    const context = useContext(mainContext);
    const {
        setCanvasTitle,
        setCanvasSubTitle
    } = context;

    useEffect(() => {
        setCanvasTitle("Add new");
        setCanvasSubTitle("Rider FAQ");
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="edit_notification_main_div"
                style={{ height: "85%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                <div>
                    <div className="input_box">
                        <label htmlFor="question">Question</label>
                        <textarea id='question' placeholder='Enter here' style={{ height: "10vh" }} />
                    </div>


                    <div className="input_box">
                        <label htmlFor="answer">Answer</label>
                        <textarea id="answer" placeholder='Enter here' style={{ height: "40vh" }}
                        ></textarea>
                    </div>
                </div>

                <div className='btns'>
                    <button className='add_btn'>Add new FAQ</button>
                </div>

            </div>
        </>
    )
}

export default AddFAQRider
