import React from 'react'
import "./Invoice.css"
const Invoice = () => {
    return (
        <>
            <div className="invoice_main_div">
                <div>

                    <div>
                        <div>
                            <span>Coustomer name</span>
                            <div>Customer</div>
                        </div>

                        <div>
                            <span style={{ textAlign: "right" }} >Driver name</span>
                            <div>Driver</div>
                        </div>
                    </div>


                    <div>
                        <div>
                            <span>Phone no.</span>
                            <div>+910000000</div>
                        </div>

                        <div>
                            <span style={{ textAlign: "right" }} >Phone no.</span>
                            <div>+91923744287</div>
                        </div>
                    </div>


                    <div>
                        <div>
                            <span>Email address</span>
                            <div>Email</div>
                        </div>

                        <div>
                            <span style={{ textAlign: "right" }} >Email address</span>
                            <div>Email Address</div>
                        </div>
                    </div>

                    <hr />
                    <h4>Trip sumary</h4>

                    <div>
                        <div>
                            <span>Pick location</span>
                            <div>sample address</div>
                        </div>

                        <div>
                            <span style={{ textAlign: "right" }} >Drop locatiopn</span>
                            <div>sample drop location</div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <span>Vehicle</span>
                            <div>AUDI Q5 (638ju)</div>
                        </div>
                    </div>


                    <hr />
                    <h4>Cost summary</h4>


                    <div>
                        <h2>Maseeha luxury</h2>
                        <h2>35.00</h2>
                    </div>


                    <div>
                        <p>Total distance covered</p>
                        <p>24.6 km</p>
                    </div>


                    <div>
                        <p>Total time taken</p>
                        <p>31 min</p>
                    </div>


                    <div>
                        <p>Discount 20% of</p>
                        <p>7.00</p>
                    </div>


                    <div>
                        <p>Taxes applicable</p>
                        <p>0.00</p>
                    </div>


                    <div>
                        <p>Other charges</p>
                        <p>0.00</p>
                    </div>


                </div>


                <div className='btns'>
                    <button className="add_btn">Download invoice PDF</button>
                </div>
            </div>
        </>
    )
}

export default Invoice
