import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const AddRefferal = () => {
    const context = useContext(mainContext);
    const { setCanvasSubTitle, setCanvasTitle } = context;
    useEffect(() => {
        setCanvasTitle("Add Refferal reward");
        setCanvasSubTitle("Update")
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="driver_level_main_div">
                <div>
                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className='input_box' >
                        <label htmlFor="date">Signup threshold</label>
                        <div>
                            <input type="number" placeholder='00' id='signup_from' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0", marginRight: "2rem" }} htmlFor='signup_from'>from</label>
                            <input type="number" placeholder='00' id='signup_to' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0" }} htmlFor='signup_to'>to</label>
                        </div>
                    </div>

                    <div className="input_box">
                        <label htmlFor="sender">Sender commission (SAR)</label>
                        <input type="number" placeholder='00.00' id='sender' />
                    </div>

                    <div className='input_box' >
                        <label htmlFor="receiver">Reciever commission (SAR)</label>
                        <input type="number" placeholder='00.00' id='receiver' />
                    </div>

                    <div className='input_box' >
                        <label htmlFor="category">Reciever commission (SAR)</label>
                        <input type="text" placeholder='enter here' id='category' />
                    </div>

                </div>

                <div className='btns'>
                    <button className='add_btn'>Add referal reward</button>
                </div>
            </div>
        </>
    )
}

export default AddRefferal
