import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const ViewNotification = () => {
    const context = useContext(mainContext);
    const { setinCanvas, setCanvasSubTitle, setCanvasTitle } = context;
    const handleEdit = () => {
        setinCanvas("Edit notification")
    }
    useEffect(() => {
        setCanvasTitle("Notification");
        setCanvasSubTitle("Details");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="edit_notification_main_div">
                <div className="input_box">
                    <div className="title">Status</div>
                    <div className="select_box">
                        <div>
                            <input type="checkbox" name="" id="status_active" />
                            <label htmlFor="status_active">Active</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="status_inactive" />
                            <label htmlFor="status_inactive">Inactive</label>
                        </div>
                    </div>
                </div>

                <div className="input_box">
                    <div className="title">Level</div>
                    <div className="select_box">
                        <div>
                            <input type="checkbox" name="" id="gold_input" />
                            <label htmlFor="gold_input">Gold</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="platinum_input" />
                            <label htmlFor="platinum_input">Platinum</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="blue_input" />
                            <label htmlFor="blue_input">Blue</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="all_input" />
                            <label htmlFor="all_input">All</label>
                        </div>
                    </div>
                </div>

                <div className="input_box">
                    <div className="title">Profile</div>
                    <div className="select_box">
                        <div>
                            <input type="checkbox" name="" id="personal_input" />
                            <label htmlFor="personal_input">Personal</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="business_input" />
                            <label htmlFor="business_input">Business</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="all_input" />
                            <label htmlFor="all_input">All</label>
                        </div>
                    </div>
                </div>

                <div className="input_box">
                    <div className="title">Category</div>
                    <div className="select_box">
                        <div>
                            <input type="checkbox" name="" id="rider_input" />
                            <label htmlFor="rider_input">Rider</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="driver_input" />
                            <label htmlFor="driver_input">Driver</label>
                        </div>
                    </div>
                </div>

                <div className="input_box">
                    <div className="title">Notification method</div>
                    <div className="select_box">
                        <div>
                            <input type="checkbox" name="" id="email_input" />
                            <label htmlFor="email_input">Email</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="sms_input" />
                            <label htmlFor="sms_input">SMS</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="push_input" />
                            <label htmlFor="push_input">Push</label>
                        </div>
                    </div>
                </div>

                <div className="input_box">
                    <div className="title">Notification type</div>
                    <div className="select_box">
                        <div>
                            <input type="checkbox" name="" id="invoice_input" />
                            <label htmlFor="invoice_input">Invoice</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="offers_input" />
                            <label htmlFor="offer_input">Offer</label>
                        </div>
                        <div>
                            <input type="checkbox" name="" id="payout_input" />
                            <label htmlFor="payout_input">Payouts</label>
                        </div>
                    </div>
                </div>

                <div className="input_box" style={{ marginTop: "2rem" }}>
                    <label htmlFor="notification_subject">Subject</label>
                    <input type="text" id='notification_subject' placeholder='enter here' />
                </div>


                <div className="input_box">
                    <label htmlFor="notification_body">Body</label>
                    <textarea id="notification_body" placeholder='enter here'></textarea>
                </div>

                <div className='btns'>
                    <button className='proccess_btn' onClick={handleEdit}>Edit notification</button>
                    <button className='delete_btn'>Delete notification</button>
                </div>
            </div>
        </>
    )
}

export default ViewNotification
