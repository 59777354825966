import React from 'react'

const AddSurgeLocation = () => {
    return (
        <>
            <div className="add_new_surge_main_div">
                <div>
                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Vehicle</div>
                        <div className='select_box'>
                            <select name="" id="">
                                <option value="">Select</option>
                            </select>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Area level</div>
                        <input type="text" placeholder='enter here' />
                    </div>

                    <div className="input_box">
                        <div className="title">Surge multiplied</div>
                        <input type="text" placeholder='enter here' />
                    </div>


                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box">
                            <div>
                                <input type="checkbox" name="" id="check_sun" />
                                <label htmlFor="check_sun">Sun</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="check_mon" />
                                <label htmlFor="check_mon">Mon</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="check_tue" />
                                <label htmlFor="check_tue">Tue</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="check_wed" />
                                <label htmlFor="check_wed">Wed</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="check_thu" />
                                <label htmlFor="check_thu">Thu</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="check_fri" />
                                <label htmlFor="check_fri">Fri</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="check_sat" />
                                <label htmlFor="check_sat">Sat</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Time (from)</div>
                        <input type="time" placeholder='enter here' />
                    </div>

                    <div className="input_box">
                        <div className="title">Tome (to)</div>
                        <input type="time" placeholder='enter here' />
                    </div>
                </div>


                <div className='btns'>
                    <button className='add_btn'>Add new surge & proceed to location</button>
                </div>
            </div>
        </>
    )
}

export default AddSurgeLocation
