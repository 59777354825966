import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext'

const AddNewDriver = () => {
    const context = useContext(mainContext);
    const { setinCanvas, setCanvasSubTitle, setCanvasTitle } = context;
    
    const handleProceed = () => {
        setinCanvas("Add driver bank");
    }
    
    useEffect(() => {
        setCanvasTitle("Driver");
        setCanvasSubTitle("Add basic information")
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="edit_profile_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Full name</label>
                        <input type="text" placeholder='enter name here' id='driver_name' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select>
                                <option>+971</option>
                                <option>+91</option>
                                <option>+201</option>
                                <option>+111</option>
                            </select>
                            <input type="number" placeholder='0000000000' id='driver_mobile' />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <input type="text" placeholder='enter email here' id='driver_email' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Profile image</label>
                        <div style={{ margin: "5% 0" }}>
                            <button>Upload profile image</button>
                        </div>
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Vehicle</label>
                        <div>

                            <input type="text" placeholder='Select vehicle' />
                            <select>
                                <option value="1">Mini</option>
                                <option value="2">Large</option>
                                <option value="3">Laxuery</option>
                                <option></option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='lower_section'>
                    <button onClick={handleProceed}>Proceed to bank detail</button>
                </div>
            </div>
        </>
    )
}

export default AddNewDriver
