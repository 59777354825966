import React from 'react'
import { Link } from 'react-router-dom';

const ManagementElementCard = (props) => {
    const { link, per, status, num, title, desc } = props;
    return (
        <>
            <div className="ManagementElementCard">
                <span>
                    {
                        per ?
                            <>
                                <div className={status ? "green" : "red"}>
                                    <i className="bi bi-bar-chart-line"></i> -{per}%
                                </div>
                                since last month
                            </>
                            : ""
                    }
                </span>
                <h1>{num}</h1>
                <h2>{title}</h2>
                <p>{desc}</p>
                <div className="mebtns_div">
                    {/* <button>Add more {title.split(" ")[0]}</button> */}
                    <Link to={link}>View {title.split(" ")[0]}</Link>
                </div>
            </div>
        </>
    )
}

export default ManagementElementCard
