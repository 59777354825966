import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext'

const AddDriverBank = () => {
    const context = useContext(mainContext);
    const { setinCanvas } = context;
    const handleBack = () => {
        setinCanvas("Add new driver");
    }
    const handleProceed = () => {
        setinCanvas("Add driver document");
    }
    return (
        <>
            <div className="edit_driver_bank_details_main_div">
                <div>

                    <div className='input_box'>
                        <label htmlFor="">Bank name</label>
                        <input type="text" placeholder='enter here' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Account number</label>
                        <input type="text" placeholder='XXXX XXXX XXXX' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Account name</label>
                        <input type="text" placeholder='enter here' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">BIC code</label>
                        <input type="text" placeholder='enter here' />
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Bank address</label>
                        <textarea placeholder='enter here'></textarea>
                    </div>
                </div>


                <div className="add_driver_bank_btns">
                    <button onClick={handleBack}>Back to basic info</button>
                    <button className="add_btn" onClick={handleProceed}>Proceed to eKYC</button>
                </div>
            </div>
        </>
    )
}

export default AddDriverBank
