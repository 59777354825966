import React, { useContext } from 'react'
import mainContext from '../../contexts/mainContext';

const AdminNotificationsListCard = (props) => {
    const context = useContext(mainContext);
    const { status } = props;
    const { setinCanvas } = context;
    const handleClick = () => {
        setinCanvas("Notification");
    }
    return (
        <>
            <div className="list_card notifications_list_grid">
                <div>
                    <div className={status ? "list_card_status status_pending" : "list_card_status status_expired"}>{status ? "unread" : "read"}</div>
                </div>
                <div>Notification</div>
                <div style={{ textAlign: "right" }} > issued from</div>
                <div style={{ textAlign: "right" }}>Date adn time</div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>View</button>
                </div>
            </div>
        </>
    )
}

export default AdminNotificationsListCard
