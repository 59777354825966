import React, { useContext, useEffect } from 'react';
import mainContext from '../../../contexts/mainContext';
import "./Filter.css";
const Filter = () => {
    const context = useContext(mainContext);
    const {
        setCanvasTitle
    } = context;
    useEffect(() => {
        setCanvasTitle("Filter");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="filter_main_div">
                <div>

                    <div className="input_box">
                        <div className="title">Via status</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>

                            <div>
                                <input type="checkbox" name="" id="all" />
                                <label htmlFor="all">All</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Via ride status</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="ride_in_transit" />
                                <label htmlFor="ride_in_transit" style={{ display: "block" }}>In transit</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="ride_stopped" />
                                <label htmlFor="ride_stopped">Stopped</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <label htmlFor="date">via Date</label>
                        <input type="date" id='date' />
                    </div>

                    <div className="input_box">
                        <label htmlFor="date">via Time</label>
                        <input type="time" id='time' />
                    </div>

                </div>

                <div className="btns">
                    <button >Aplly filter</button>
                    <button className='delete_btn'>Reset filter</button>
                </div>
            </div>
        </>
    )
}

export default Filter
