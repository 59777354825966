import React from 'react'

const AnalyticsPageCard = (props) => {
    const { status, title, num, per, desc, icon } = props;
    return (
        <>
            <div className="analytics_card_main_body">
                <div className="analytics_card_header">
                    <span></span>
                    {title}
                </div>
                <div className="analytics_card_body">
                    <div>
                        {num} &thinsp;
                        <span className={status ? "green" : "red"}>
                            {
                                icon ?
                                    <i className="bi bi-bar-chart-line"></i>
                                    :
                                    ""
                            }
                        </span>
                    </div>
                    <div className={status ? "true" : "green"} style={{ fontSize: "60%" }}>
                        <span className={status ? "green" : "red"}>
                            {per} {desc}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnalyticsPageCard
