import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const EditEmergencyNumber = () => {

    const context = useContext(mainContext);
    const {
        setCanvasTitle,
        setCanvasSubTitle
    } = context;

    useEffect(() => {
        setCanvasTitle("Edit emergency number");
        setCanvasSubTitle("Detail");
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="knowledge_base_view_main_div">
                <div>

                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" id="checkbox_active" />
                                <label htmlFor="checkbox_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" id="checkbox_inactive" />
                                <label htmlFor="checkbox_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="number">Emergency number</div>
                        <input type="number" placeholder='XX XXXX' id='number' />
                    </div>

                    <div className="input_box">
                        <label htmlFor="desc">Emergency decription</label>
                        <textarea id='desc' placeholder='Enter description here' style={{ height: "10vh" }} />
                    </div>

                </div>


                <div className="btns">
                    <button className='add_btn'>Update number</button>
                    <button className='delete_btn'>Delete number</button>
                </div>
            </div>
        </>
    )
}

export default EditEmergencyNumber
