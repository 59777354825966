import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './Components/Login/LoginPage.jsx';
import MainState from './contexts/MainState';
import HomePage from './Components/Home/HomePage';
import LogoutPage from './Components/Login/LogoutPage';

function App() {
  return (
    <>
      <MainState>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/Admin-Notifications" element={<HomePage />} />
            <Route path="/Management" element={<HomePage />} />
            
            <Route path="/Management/Organisations" element={<HomePage />} />
            <Route path="/Management/Organisations/Detail" element={<HomePage />} />
            <Route path="/Management/Organisations/Detail/Employee" element={<HomePage />} />
            <Route path="/Management/Organisations/Detail/Expenditures" element={<HomePage />} />

            <Route path="/Management/User" element={<HomePage />} />
            <Route path="/Management/website-user" element={<HomePage />} />

          </Routes>
        </BrowserRouter>
      </MainState>
    </>
  );
}

export default App;
