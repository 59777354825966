import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const OrganisationListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setOrganisationDetail } = context;

    const { name, added, email, mobile, country, currency, item } = props;

    const handleClick = () => {
        setOrganisationDetail(() => { return item });
        setinCanvas(`Edit organisation profile`)
    }

    const handleClick2 = () => {
        setOrganisationDetail(() => { return item });
        setinCanvas(`Change Organisation Password`)
    }

    return (
        <div>
            <div className="list_card organisations_list_grid" style={{ gridTemplateColumns: "3fr 3fr 2fr 2fr 1.5fr 1.5fr 4fr" }}>
                <div>{name}</div>
                <div style={{ textTransform: "none" }}>{email}</div>
                <div>{mobile}</div>
                <div>{country}</div>
                <div style={{ textAlign: "right" }}>{currency}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", gap: "2vh", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit</button>
                    <button className="list_card_view_btn" onClick={handleClick2}>Password</button>
                </div>
            </div>
        </div>
    )
}

export default OrganisationListCard
