import React, { useEffect, useRef, useContext } from 'react';
import Notifications from './Notifications/Notifications';
import AdminProfile from './Profile/AdminProfile';
import mainContext from '../../contexts/mainContext';
import "./OffCanvas.css";
import "./Profile/Profile.css";
import "./EditSection/Edit.css";
import Close_btn from './Assets/Menu.svg';
import Filter from './Filter/Filter'
import Invoice from './Invoice/Invoice'
import EditDriverBankDetails from './EditBankDetails/EditDriverBankDetails';
import EditAdminProfile from './EditSection/EditAdminProfile';
import EditDriverProfile from './EditSection/EditDriverProfile';
import EditNotification from './EditSection/EditNotification';
import AddKnowledgeBase from './AddSections/AddKnowledgeBase';
import KnowledgeBase from './ViewSection/KnowledgeBase';
import AddSurgeLocation from './AddSections/AddSurgeLocation';
import EditPromo from './EditSection/EditPromo';
import AddNewPromo from './AddSections/AddNewPromo';
import AddNewDriver from './AddSections/AddNewDriver';
import AddDriverBank from './AddSections/AddDriverBank';
import AddDriverDocument from './AddSections/AddDriverDocument';
import AddCompnay from './AddSections/AddCompnay';
import EditCompanyBankDetails from './EditBankDetails/EditCompanyBankDetails';
import AddCompanyBankDetails from './AddSections/AddCompanyBankDetails';
import AddCompanyDocuments from './AddSections/AddCompanyDocuments';
import EditCompany from './EditSection/EditCompany';
import EditCompanydocuments from './EditSection/EditCompanydocuments'
import FAQRider from './ViewSection/FAQRider';
import FAQDriver from './ViewSection/FAQDriver';
import EditFAQRider from './EditSection/EditFAQRider';
import EditFAQDriver from './EditSection/EditFAQDriver';
import AddFAQRider from './AddSections/AddFAQRider';
import AddOrganisation from './AddSections/AddOrganisation';
import AddOrganisationDocuments from './AddSections/AddOrganisationDocuments';
import AddFAQDriver from './AddSections/AddFAQDriver';
import OrganisationProfile from './Profile/OrganisationProfile';
import EditOrganisationProfile from './EditSection/EditOrganisationProfile';
import EditEmployeeProfile from './EditSection/EditEmployeeProfile';
import AddDriverLevel from './AddSections/AddDriverLevel';
import EditDriverLevel from './EditSection/EditDriverLevel';
import EditRefferal from './EditSection/EditRefferal';
import AddRefferal from './AddSections/AddRefferal';
import AddNotification from './AddSections/AddNotification';
import ViewNotification from './ViewSection/ViewNotification';
import EditKnowledgeBase from './EditSection/EditKnowledgeBase';
import AddEmergencyNumber from './AddSections/AddEmergencyNumber';
import EditEmergencyNumber from './EditSection/EditEmergencyNumber'
import EmergencyNumberFilter from './Filter/EmergencyNumberFilter';

import OrganisationFilter from './Filter/organisation/organisationfilter';
import ChangeOrganisationpassword from './password/organisationpassword';
import AddOrganisationBankDetail from './AddSections/AddOrganisationBank';
import EditOrganisationBankDetail from './AddSections/EditOrganisationBank';
import AddUser from './AddSections/AddUser';
import EditUser from './EditSection/EditUser';

const OffCanvas = () => {
    
    const firstUpdate = useRef(true);
    const context = useContext(mainContext);
    
    const {
        inCanvas,
        setinCanvas,
        canvasTitle,
        canvasSubTitle,
        allFunctions,
        setAllFunctions,
    } = context;
    
    const handleCanvasClose = () => {
        document.querySelector(".off_canvas_main_div").classList.remove("off_canvas_active");
        setinCanvas("unset");
    }
    
    useEffect(() => {
        setAllFunctions({ handleCanvasClose, ...allFunctions })
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (inCanvas === "unset") {
            return;
        }
        else {
            document.querySelector(".off_canvas_main_div").classList.add("off_canvas_active");
        }
        // eslint-disable-next-line
    }, [inCanvas]);

    return (
        <>
            <div className="off_canvas_main_div">
                <span className="before" onClick={handleCanvasClose}></span>
                <div className="off_canvas_content">
                    <div className="off_canvas_header">
                        <div className="off_canvs_title">
                            <h3>{canvasTitle}</h3>
                            <small>{canvasSubTitle}</small>
                        </div>
                        <span onClick={handleCanvasClose}><img src={Close_btn} alt='' /></span>
                    </div>
                    {
                        inCanvas === "filter" &&
                        <Filter />
                    }

                    {
                        inCanvas === "organisation-filter" &&
                        <OrganisationFilter />
                    }


                    {/* -------------End of filter */}

                    {/* -------------password change ------------------- */}

                    {
                        inCanvas === "Change Organisation Password" &&
                        <ChangeOrganisationpassword />
                    }

                    {/* =============End of password chnage ============= */}


                    {
                        inCanvas === "Trip invoice" &&
                        <Invoice />
                    }
                    {
                        inCanvas === "Notification" &&
                        <Notifications />
                    }
                    {
                        inCanvas === "Admin Profile" &&
                        <AdminProfile />
                    }
                    {
                        inCanvas === "Organisation profile" &&
                        <OrganisationProfile />
                    }
                    {
                        inCanvas === "Employee profile" &&
                        <EditEmployeeProfile />
                    }
                    {
                        inCanvas === "faq-rider" &&
                        <FAQRider />
                    }
                    {
                        inCanvas === "faq-driver" &&
                        <FAQDriver />
                    }
                    {
                        inCanvas === "View notification" &&
                        <ViewNotification />
                    }
                    {
                        inCanvas === "Edit admin profile" &&
                        <EditAdminProfile />
                    }
                    {
                        inCanvas === "Edit driver profile" &&
                        <EditDriverProfile />
                    }
                    {
                        inCanvas === "Edit driver bank details" &&
                        <EditDriverBankDetails />
                    }
                    {
                        inCanvas === "Edit company bank details" &&
                        <EditCompanyBankDetails />
                    }
                    {
                        inCanvas === "Edit company documents" &&
                        <EditCompanydocuments />
                    }
                    {
                        inCanvas === "Edit organisation profile" &&
                        <EditOrganisationProfile />
                    }
                    {
                        inCanvas === "Edit Organisation Bank" &&
                        <EditOrganisationBankDetail />
                    }
                    {
                        inCanvas === "Edit faq-rider" &&
                        <EditFAQRider />
                    }
                    {
                        inCanvas === "Edit faq-driver" &&
                        <EditFAQDriver />
                    }
                    {
                        inCanvas === "Add new faq-rider" &&
                        <AddFAQRider />
                    }
                    {
                        inCanvas === "Add new faq-driver" &&
                        <AddFAQDriver />
                    }
                    {
                        inCanvas === "Add new companies" &&
                        <AddCompnay />
                    }
                    {
                        inCanvas === "Add new organisation" &&
                        <AddOrganisation />
                    }
                    {
                        inCanvas === "Add Organisation Bank" &&
                        <AddOrganisationBankDetail />
                    }

                    {
                        inCanvas === "Add company bank details" &&
                        <AddCompanyBankDetails />
                    }
                    {
                        inCanvas === "Add company documents" &&
                        <AddCompanyDocuments />
                    }
                    {
                        inCanvas === "Add organisation documents" &&
                        <AddOrganisationDocuments />
                    }
                    {
                        inCanvas === "Add new driver" &&
                        <AddNewDriver />
                    }
                    {
                        inCanvas === "Add driver document" &&
                        <AddDriverDocument />
                    }
                    {
                        inCanvas === "Add driver bank" &&
                        <AddDriverBank />
                    }
                    {
                        inCanvas === "Add new surge location" &&
                        <AddSurgeLocation />
                    }
                    {
                        inCanvas === "Add new driver levels" &&
                        <AddDriverLevel />
                    }
                    {
                        inCanvas === "Add new notifications" &&
                        <AddNotification />
                    }
                    {
                        inCanvas === "Edit driver level" &&
                        <EditDriverLevel />
                    }
                    {
                        inCanvas === "Edit notification" &&
                        <EditNotification />
                    }
                    {
                        inCanvas === "Edit company" &&
                        <EditCompany />
                    }
                    {
                        inCanvas === "Add new knowledgebase" &&
                        <AddKnowledgeBase />
                    }
                    {
                        inCanvas === "Edit knowledgebase" &&
                        <EditKnowledgeBase />
                    }
                    {
                        inCanvas === "knowledgebase" &&
                        <KnowledgeBase />
                    }
                    {
                        inCanvas === "Add new emergency numbers" &&
                        <AddEmergencyNumber />
                    }
                    {
                        inCanvas === "Edit emergency number" &&
                        <EditEmergencyNumber />
                    }
                    {
                        inCanvas === "Add new promos & offers" &&
                        <AddNewPromo />
                    }
                    {
                        inCanvas === "Edit promo" &&
                        <EditPromo />
                    }
                    {
                        inCanvas === "Edit refferal" &&
                        <EditRefferal />
                    }
                    {
                        inCanvas === "Add new referral rewards" &&
                        <AddRefferal />
                    }
                    {/* ------------------------------------ */}
                    {
                        inCanvas === "Add new user" &&
                        <AddUser />
                    }
                    {
                        inCanvas === "Edit user profile" &&
                        <EditUser />
                    }

                    {/* FILTERS OFF CANVASES */}
                    {
                        inCanvas === "EmergencyNumberFilter" &&
                        <EmergencyNumberFilter/>
                    }
                </div>
            </div>
        </>
    )
}

export default OffCanvas
