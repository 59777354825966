import axios from "axios";
const authToken = localStorage.getItem("authToken");
// const authToken = process.env.REACT_APP_AUTHTOKEN;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Suffix = process.env.REACT_APP_SUFFIX;

class Organisation {

    async createOrganisation( data, file = undefined ){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/admin/organisation`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getOrganisationList(param) {
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/admin/organisation${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;
        } catch (error) {
            return error.response
        }
    }

    async updateOrganisation(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/admin/organisation`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async changePassword(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/change-password`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getUserList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/admin/user${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;
        } catch (error) {
            return error.response
        }
    }

    async createUser(data){
        try{

            const config = {
                method: 'post',
                url: `${SERVER_URL}/${Suffix}/admin/user`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async updateUser(data){
        try{

            const config = {
                method: 'put',
                url: `${SERVER_URL}/${Suffix}/admin/user`,
                data,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios(config);
            return res;


        }catch(err){
            return err.response
        }
    }

    async getWebsiteUserList(param){
        const { limit, page, filterDate } = param;
        
        try {

            let parseUrl = `?`;
            parseUrl = limit ? `${parseUrl}limit=${limit}&` : parseUrl ;
            parseUrl = page ? `${parseUrl}page=${page}&` : parseUrl ;

            if( filterDate.from && filterDate.to ) {
                parseUrl = `${parseUrl}from=${filterDate.from}&to=${filterDate.to}`
            }

            const config = {
                method: 'get',
                url: `${SERVER_URL}/${Suffix}/admin/webuser${parseUrl}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
            };
            const res = await axios(config);
            return res;
        } catch (error) {
            return error.response
        }
    }

}
const organisation = new Organisation();
export default organisation;