import React, { useContext, useEffect, useState } from 'react'
import mainContext from '../../../contexts/mainContext';
// import UploadPhoto from '../../../Controller/UploadPhoto';
import { ToastContainer, toast } from 'react-toastify';
import Organisation from '../../../Controller/Organisation';

// const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ChangeOrganisationpassword = () => {
    
    const url = new URLSearchParams(window.location.search);
    const context = useContext(mainContext);
    const { 
        setCanvasTitle,
        allFunctions,
        organisationDetail,
        // setinCanvas,  
        setCanvasSubTitle 
    } = context;

    const { handleCanvasClose, getOrganisationList  } = allFunctions
    
    const intialState = { 
        password: '',
        confirmpassword: ''
    };
    const [ passwordState, setPasswordState ] = useState(intialState);
    const { password, confirmpassword } = passwordState;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswordState((intialState) => { return { ...intialState, [name]: value } })
    }

    const handleProceed = async () => {

        if(password !== confirmpassword){
            return toast.error( "confirm password is not matched with password" ,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        if(password === ''){
            return toast.error( "password cannot be set blank" ,{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const data = { _id: organisationDetail._id, password: password }

        console.log({ data })
        const res = await Organisation.changePassword(data);
        console.log({ res })
        if(res && (res.status === 200 || res.status === 201)){
            toast.success("Password Is generated Successfully",{
                pauseOnHover: false,
                theme: "light"
            });

            setTimeout(async() => {
                await getOrganisationList(url.get('page'))
                await handleCanvasClose();
            }, 2000)
        }
        else{
            if(typeof(res.data.message) == 'object' )
                return toast.error(res.data.message ? res.data.message[0] : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            else{
                return toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                    pauseOnHover: false,
                    theme: "light"
                });
            }

        }

    }
    
    // const handleImg = (id) => {
    //     console.log('handle image')
    //     document.getElementById(id).click();
    // }
    
    useEffect(() => {
        setCanvasTitle("Change Password");
        setCanvasSubTitle("Organisation");
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="add_company_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Password</label>
                        <input type="text" placeholder='Password' id='compnay_name' name='password' value={password} onChange={handleChange} />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Confirm password</label>
                        <div className='select_input'>
                            <input type="password" placeholder='Confirm Password' id='select_input' name='confirmpassword' value={confirmpassword} onChange={handleChange} />
                        </div>
                    </div>

                </div>
                <div className='lower_section'>
                    <button onClick={handleProceed}>Change Password</button>
                </div>
            </div>
        </>
    )
}

export default ChangeOrganisationpassword
