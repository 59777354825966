import React from 'react'

const AddDriverDocument = () => {
    return (
        <>
            <div className="add_driver_document_main_div">
                <div>
                    <section>
                        <details>
                            <summary style={{ border: "none" }}>
                                <input type="checkbox" name="" id="" /> National ID
                            </summary>
                            <div className='input_box'>
                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>

                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>
                            </div>
                        </details>


                        <details>
                            <summary style={{ border: "none" }}>
                                <input type="checkbox" name="" id="" /> Driving licence
                            </summary>
                            <div className='input_box'>
                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>

                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>
                            </div>
                        </details>
                    </section>
                </div>
                <div className="btns">
                    <button className='status_processing'>Back to bank details</button>
                    <button className='add_btn'>Send for approval</button>
                </div>
            </div>
        </>
    )
}

export default AddDriverDocument
