import React, { useContext, useEffect } from 'react';
import mainContext from '../../../contexts/mainContext'
const EditDriverProfile = () => {
    
    const context = useContext(mainContext);
    const { setCanvasSubTitle, setCanvasTitle } = context;
    
    useEffect(() => {
        setCanvasTitle("Edit driver profile");
        setCanvasSubTitle("Update")
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="edit_profile_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Full name</label>
                        <input type="text" placeholder='enter name here' id='driver_name' />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select>
                                <option>+971</option>
                                <option value="1">+91</option>
                                <option value="2">+201</option>
                                <option value="3">+111</option>
                            </select>
                            <input type="number" placeholder='0000000000' id='driver_mobile' />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <input type="text" placeholder='enter email here' id='driver_email' />
                    </div>

                    <div className='input_box img_div'>
                        <label htmlFor="">Profile image</label>
                        <div>
                            <img src="https://picsum.photos/200/300" alt="" />
                            <button>Replace image</button>
                        </div>
                    </div>
                    <div className='input_box'>
                        <label htmlFor="">Vehicle</label>
                        <div>

                            <input type="text" placeholder='Select vehicle' />
                            <select>
                                <option value="1">Mini</option>
                                <option value="2">Large</option>
                                <option value="3">Laxuery</option>
                                <option></option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='lower_section'>
                    <button>Update profile</button>
                </div>
            </div>
        </>
    )
}

export default EditDriverProfile
