import React, { useContext } from 'react'
import mainContext from '../../../contexts/mainContext';

const UserListCard = (props) => {

    const context = useContext(mainContext);
    const { setinCanvas, setUserDetail } = context;

    const { name, added, email, mobile, item } = props;

    const handleClick = () => {
        setUserDetail(() => { return item });
        setinCanvas(`Edit user profile`)
    }

    return (
        <div>
            <div className="list_card organisations_list_grid" style={{ gridTemplateColumns: "3fr 3fr 2fr 2fr 4fr" }}>
                <div>{name}</div>
                <div>{email}</div>
                <div>{mobile}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", gap: "2vh", justifyContent: "flex-end" }}>
                    <button className="list_card_view_btn" onClick={handleClick}>Edit</button>
                </div>
            </div>
        </div>
    )
}

export default UserListCard
