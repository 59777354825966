import React from 'react'
import "./ManagementPageBody.css"
import ManagementElementCard from "./ManagementElementCard.jsx"
const ManagementPageBody = () => {
    return (
        <>
            <div className="management_body">
                {/* <ManagementElementCard status={false} link="/Management/Companies" per="10.5" num="27" title="comapnies" desc="Manage companies with multiple drivers & vehicles" /> */}
                <ManagementElementCard status={true} link="/Management/Organisations" per="" num="198" title="organisation" desc="Manage organisation with multiple riders & vouchers" />
                {/* <ManagementElementCard status={true} link="/Management/Surge-locations" per="19.6" num="27" title="surgue location" desc="Manage geo location, surge multipler logic" /> */}
                {/* <ManagementElementCard status={true} link="/Management/Driver-levels" num="27" title="Driver level" desc="Manage driver level logic, rewards & threshold rider" /> */}
                {/* <ManagementElementCard status={true} link="/Management/Referral-rewards" num="27" title="refferral-rewards" desc="Manage rewards rider or driver will achieve and send on refreal" /> */}
                {/* <ManagementElementCard status={true} link="/Management/Notifications" num="27" title="Notification" desc="Manage email, sms, push notification template" /> */}
                {/* <ManagementElementCard status={true} link="/Management/Knowledgebase" num="27" title="Knowledgebase" desc="Manage how -to articles, videos for driver welfare" /> */}
                {/* <ManagementElementCard status={true} link="/Management/Emergency-numbers" num="27" title="Emergency-numbers" desc="Manage emergency number on rider's or driver's application" /> */}
            </div>
        </>
    )
}

export default ManagementPageBody
