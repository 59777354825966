import React, { useContext, useState, useEffect } from 'react';
import mainContext from '../../../contexts/mainContext';
// import UploadPhoto from '../../../Controller/UploadPhoto';
import { ToastContainer, toast } from 'react-toastify';
import Auth from '../../../Controller/Auth';

// const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const EditAdminProfile = () => {
    
    const context = useContext(mainContext);
    const { setCanvasTitle, loginUserInfo, setLoginUserInfo, allFunctions } = context;
    
    useEffect(() => {
        setCanvasTitle("Edit your profile");
        // eslint-disable-next-line
    }, [])

    const initialState = { ...loginUserInfo, password: '' }
    const [ adminState, setAdminState ] = useState(initialState);
    const { name, email, mobileno, countrycode, password } = adminState;

    const { handleCanvasClose } = allFunctions

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAdminState((initial) => { return { ...initial, [name]: value } })
    }

    // const changeAvatar = async(e) => {

    //     const { name } = e.target;

    //     const err = await UploadPhoto.checkImage(e.target.files[0]);
    //     if(err) {
    //         return toast.warning(err,{
    //             pauseOnHover: false,
    //             theme: "light"
    //         });
    //     }

    //     const image = await UploadPhoto.uploadPhoto(e.target.files[0]);
    //     console.log({ image })
    //     const { path } = image;
    //     setAdminState((initialState) => { return { ...initialState, [name]: path } })

    //     // setImage(e.target.files[0]);
    // }

    // const handleImg = (id) => {
    //     console.log('handle image')
    //     document.getElementById(id).click();
    // }

    const updateProfile = async() => {
        console.log({ adminState })
        if(!name || !email || !mobileno || !countrycode || !password){
            return toast.error('please filled all the details',{
                pauseOnHover: false,
                theme: "light"
            });
        }

        const res = await Auth.updateProfile(adminState)
            if(res.status === 200 || res.status === 201){
                setLoginUserInfo( res.data )
                toast.success("Admin Is Updated Successfully",{
                    pauseOnHover: false,
                    theme: "light"
                });

                setTimeout(async() => {
                    await handleCanvasClose();
                }, 2000)

            }else if(res.status === 403 && localStorage.getItem("authToken")){
                
            }else {

        }


    }

    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <div className="edit_profile_main_div">
                <div className='upper_section'>
                    <div className='input_box'>
                        <label htmlFor="">Full name</label>
                        <input type="text" placeholder='enter name here' name='name' value={name} onChange={handleChange} />
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Phone number</label>
                        <div>
                            <select name="countrycode" onChange={handleChange} value={countrycode}>
                                <option value=""></option>
                                <option value="91">+91</option>
                                <option value="201">+201</option>
                                <option value="111">+111</option>
                            </select>
                            <input type="number" placeholder='0000' name="mobileno" onChange={handleChange} value={mobileno} />
                        </div>
                    </div>

                    <div className='input_box'>
                        <label htmlFor="">Email address</label>
                        <input type="email" placeholder='email@Maseeha.in' name='email' value={email} onChange={handleChange} />
                    </div>

                    {/* <div className='input_box upload_photo'>
                        <label htmlFor="">Profile Image</label>
                        <input type="file" style={{ display: "none" }} id="img_input" accept=".jpg, .jpeg, .png" onChange={changeAvatar} name="profileimage" />
                        <div style={{ margin: "5% 0" }}>
                            {
                                profileimage !== ""
                                ? <img src={`${profileimage}`} style={{ width: "90px" }} alt="" />
                                : ""
                            }
                            <button onClick={() => handleImg('img_input')}>Profile Image</button>
                        </div>
                    </div> */}

                    <div className='input_box'>
                        <label htmlFor="">New password</label>
                        <input type="text" placeholder='enter here' name='password' value={password} onChange={handleChange} />
                    </div>
                </div>
                <div className='lower_section'>
                    <button onClick={updateProfile}>Update profile</button>
                </div>
            </div>
        </>
    )
}

export default EditAdminProfile
