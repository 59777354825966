import React, { useContext, useEffect } from 'react';
import mainContext from '../../../../contexts/mainContext';
import "./organisationfilter.css"

const OrganisationFilter = () => {

    const context = useContext(mainContext);
    const { setCanvasTitle, allFunctions, param } = context;
    const { getOrganisationList, handleCanvasClose } = allFunctions;

    useEffect(() => {
        setCanvasTitle("Filter");
        // eslint-disable-next-line
    }, [])
    
    const handleSubmit = async() => {
        await getOrganisationList(1, param);
        handleCanvasClose();
    }

    const resetFilter = () => {
        const res = document.getElementsByClassName('value-holder');
        for( let i=0;i<res.length;i++ ) {
            res[i].value='';
        }
    }

    return (
        <>
            <div className="filter_main_div">
                <div>

                    <div className="input_box">
                        <label htmlFor="date">via Date From</label>
                        <input type="date" className="value-holder" id='date' onChange={(e) =>  param.filterDate.from =  e.target.value  } />
                    </div>

                    <div className="input_box">
                        <label htmlFor="date">via Date To</label>
                        <input type="date" className="value-holder" id='date' onChange={(e) => param.filterDate.to =  e.target.value } />
                    </div>

                </div>

                <div className="btns">
                    <button onClick={handleSubmit}>Aplly filter</button>
                    <button className='delete_btn' onClick={resetFilter}>Reset filter</button>
                </div>
            </div>
        </>
    )
}

export default OrganisationFilter
