import React, { useContext, useEffect, useState } from 'react';
import UserListCard from './UserListCard.jsx';
import SearchHeader from '../../SearchHeader/SearchHeader.jsx';
import "./UserPage.css";
import mainContext from '../../../contexts/mainContext.jsx';
import Pagination from '../../Pagination/Pagination.jsx';
import { useNavigate } from 'react-router-dom';
import Organisation from '../../../Controller/Organisation.jsx';
import { ToastContainer, toast } from 'react-toastify';

const UserPage = () => {

    const context = useContext(mainContext);
    const url = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    const [ userList, setUserList ] = useState([]);

    let {
        param,
        totalFetchDataCount,
        setTotalFetchDataCount,
        setProfileNavTitle,
        allFunctions,
        setAllFunctions,
    } = context;

    const getUserList = async(page = 1, pass = undefined) => {
        if(!page)
            page = 1;
        navigate(`?page=${page}`)
        if(pass){
            param = pass;
        }
        param.page = page;
        const res = await Organisation.getUserList(param)
        if(res.status === 200 || res.status === 201){
            setUserList(() => res.data.results)
            setTotalFetchDataCount(res.data.count)
        }else{
            toast.error(res.data.message ? res.data.message : "SomeThing Went Wrong",{
                pauseOnHover: false,
                theme: "light"
            });
        }

    }
    
    useEffect(() => {
        getUserList(url.get("page"))
        setProfileNavTitle("Management");
        setAllFunctions({ ...allFunctions, getUserList })
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <ToastContainer autoClose={2000} position="top-center"  />
            <SearchHeader 
                addMoreSection={true} 
                addSearchSection={false} 
                addSubTitle={true} 
                subTitle="user" 
                records={totalFetchDataCount} 
                searchPlaceHolder="Search organisation" 
                filterOf="user-filter"
            />
            <div className="component_body">
                <div className="list_header organisations_list_grid" style={{ gridTemplateColumns: "3fr 3fr 2fr 2fr 4fr" }}>
                    <div>Name</div>
                    <div>Email</div>
                    <div>Mobile No</div>
                    {/* <div></div> */}
                    {/* <div style={{ textAlign: "right" }}></div> */}
                    <div style={{ textAlign: "right" }}>Added on</div>
                    <div></div>
                </div>
                <div>

                {
                        userList.length <= 0 ?
                            <div style={{ marginTop: "2vh" }}> No record found</div>
                            :
                            <>
                                {
                                    userList.map((item, index) => {
                                        return <UserListCard
                                            currentPage={url.get("page")}
                                            userId= {item.userId}
                                            key= {item._id}
                                            name= {item.name}
                                            email= {item.email}
                                            mobile= {`${item.countrycode}-${item.mobileno}`}
                                            country= {item.country}
                                            currency= {item.currency}
                                            item={item}
                                            added={`2022-08-26`}
                                        />
                                    })
                                }
                            </>
                    }

                </div>
            </div>
            {
                param.limit < totalFetchDataCount ?
                    <>
                        {
                            param.limit >= totalFetchDataCount ?
                                ""
                                :
                                <Pagination totalPage={Math.ceil(totalFetchDataCount / param.limit)} callFunction={getUserList} />
                        }
                    </>
                    :
                    ""
            }
        </>
    )
}

export default UserPage
