import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const EmergencyNumberFilter = () => {

    const context = useContext(mainContext);
    
    const {
        setCanvasTitle
    } = context;

    useEffect(() => {
        setCanvasTitle("Filter");
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <div className="filter_main_div">
                <div>

                    <div className="input_box">
                        <div className="title">Via status</div>
                        <div className="select_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>

                            <div>
                                <input type="checkbox" name="" id="all" />
                                <label htmlFor="all">All</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <label htmlFor="date">Via Date</label>
                        <strong>From</strong>
                        <input type="date" id='from_date' />
                        <strong>To</strong>
                        <input type="date" id='to_date' />
                    </div>

                </div>

                <div className="btns">
                    <button>Apply filter</button>
                    <button className='delete_btn'>Reset filter</button>
                </div>
            </div>
        </>
    )
}

export default EmergencyNumberFilter
