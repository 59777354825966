import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const AddOrganisationDocuments = () => {
    const context = useContext(mainContext);
    const { setCanvasTitle, setCanvasSubTitle } = context;
    useEffect(() => {
        setCanvasTitle("Organisation documents");
        setCanvasSubTitle("View uploaded documents");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="add_driver_document_main_div">
                <div>
                    <section>
                        <details>
                            <summary style={{ border: "none" }}>
                                <input type="checkbox" name="" id="" /> Company registration certificate
                            </summary>
                            <div className='input_box'>
                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>

                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>
                            </div>
                        </details>


                        <details>
                            <summary style={{ border: "none" }}>
                                <input type="checkbox" name="" id="" /> VAT registration certificate
                            </summary>
                            <div className='input_box'>
                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>

                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>
                            </div>
                        </details>

                        <details>
                            <summary style={{ border: "none" }}>
                                <input type="checkbox" name="" id="" /> Other document
                            </summary>
                            <div className='input_box'>
                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>

                                <div>
                                    <div className="title">Front image</div>
                                    <div className="img_div"></div>
                                    <button className='add_btn'>Replace image</button>
                                </div>
                            </div>
                        </details>
                    </section>
                </div>
                <div className="btns">
                    <button className='status_processing'>Back to basic details</button>
                    <button style={{color:"#333"}} disabled={true}>Finish organisation setup</button>
                </div>
            </div>
        </>
    )
}

export default AddOrganisationDocuments
