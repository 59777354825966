import React, { useContext, useEffect } from 'react'
import mainContext from '../../../contexts/mainContext';

const A00DriverLevel = () => {
    const context = useContext(mainContext);
    const { setCanvasSubTitle, setCanvasTitle } = context;
    useEffect(() => {
        setCanvasTitle("Add driver level");
        setCanvasSubTitle("Update");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="driver_level_main_div">
                <div>
                    <div className="input_box">
                        <div className="title">Status</div>
                        <div className="check_box">
                            <div>
                                <input type="checkbox" name="" id="status_active" />
                                <label htmlFor="status_active">Active</label>
                            </div>
                            <div>
                                <input type="checkbox" name="" id="status_inactive" />
                                <label htmlFor="status_inactive">Inactive</label>
                            </div>
                        </div>
                    </div>

                    <div className="input_box">
                        <div className="title">Level</div>
                        <input type="text" placeholder='enter here' />
                    </div>

                    <div className='input_box' >
                        <label htmlFor="date">Rides threshold</label>
                        <div>
                            <input type="number" placeholder='00' id='ride_from' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0", marginRight: "2rem" }} htmlFor='ride_from'>from</label>
                            <input type="number" placeholder='00' id='ride_to' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0" }} htmlFor='ride_to'>to</label>
                        </div>
                    </div>

                    <div className='input_box' >
                        <label htmlFor="date">Online threshold (hours)</label>
                        <div>
                            <input type="number" placeholder='00' id='hours_from' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0", marginRight: "2rem" }} htmlFor='hours_from'>from</label>
                            <input type="number" placeholder='00' id='hours_to' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0" }} htmlFor='hours_to'>to</label>
                        </div>
                    </div>

                    <div className='input_box' >
                        <label htmlFor="date">Rating</label>
                        <div>
                            <input type="number" placeholder='00' id='rating_from' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0", marginRight: "2rem" }} htmlFor='rating_from'>from</label>
                            <input type="number" placeholder='00' id='rating_to' style={{ width: "2rem" }} />
                            <label style={{ marginTop: "0" }} htmlFor='rating_to'>to</label>
                        </div>
                    </div>

                    <div className='input_box' >
                        <label htmlFor="month">Cycle</label>
                        <input type="number" placeholder='0' id='month' />
                    </div>

                </div>

                <div className='btns'>
                    <button className='add_btn'>Add driver level</button>
                </div>
            </div>
        </>
    )
}

export default A00DriverLevel
