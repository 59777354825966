import React, { useContext, useEffect } from 'react';
import mainContext from '../../contexts/mainContext';
import "./AdminNotifications.css";
import AdminNotificationsListCard from './AdminNotificationsListCard';
const AdminNotifications = () => {
    const context = useContext(mainContext);
    const { setProfileNavTitle } = context;
    useEffect(() => {
        setProfileNavTitle("Notification");
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className="notification_main_div">
                <div className="list_header notifications_list_grid">
                    <div>
                        <div>Status</div>
                    </div>
                    <div>Notification</div>
                    <div style={{ textAlign: "right" }} > issued from</div>
                    <div style={{ textAlign: "right" }}>Date adn time</div>
                    <div></div>
                </div>
                <div>
                    <AdminNotificationsListCard status={true} />
                    <AdminNotificationsListCard status={true} />
                    <AdminNotificationsListCard status={false} />
                </div>
            </div>
        </>
    )
}

export default AdminNotifications
