const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Suffix = process.env.REACT_APP_SUFFIX;

class UploadPhoto {
    
    async uploadPhoto(fileInput) {
        try {
            var formdata = new FormData();
            formdata.append("file", fileInput);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const imgUrl = await fetch(`${SERVER_URL}/${Suffix}/media`, requestOptions)
            return imgUrl.json()
        } catch (error) {
            console.log(error);
            return error
        }
    }

    async checkImage(file){
        let err = "";
        if(!file) return err= "File does not exist"
    
        if(file.size > 5 * 1024 * 1024) //1mb
        err= 'the largest image size is 1mb';
    
        if(file.type !== 'image/jpeg' && file.type !== 'image/png' )
        err = 'Image format is incorrect';
    
        return err;
    }

}
const uploadPhoto = new UploadPhoto();

export default uploadPhoto;

