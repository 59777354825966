import React from 'react';

const UserListCard = (props) => {

    const { name, added, email, mobile } = props;

    return (
        <div>
            <div className="list_card organisations_list_grid" style={{ gridTemplateColumns: "3fr 3fr 2fr 2fr 4fr" }}>
                <div>{name}</div>
                <div>{email}</div>
                <div>{mobile}</div>
                <div style={{ textAlign: "right" }}>{added}</div>
                <div style={{ display: "flex", gap: "2vh", justifyContent: "flex-end" }}>
                </div>
            </div>
        </div>
    )
}

export default UserListCard
